import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { AuthContext } from '../../../context/AuthContext'; // Adjust the path as needed
import ProductList from '../../../components/ProductList/ProductList';
import { API_BASE_URL } from '../../../config/start.mjs'; // Adjust the path as needed
import { useTranslation } from 'react-i18next';
import './Products.css'; // Ensure this CSS file exists

const Products = () => {
  const [products, setProducts] = useState([]);
  const { user } = useContext(AuthContext); 
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        if (user && user._id) {
          const response = await axios.get(`${API_BASE_URL}/api/partner/products/${user._id}`);
          setProducts(response.data);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [user]);

  if (loading) return <p>{t('loading')}</p>;
  if (error) return <p>{t('error', { message: error })}</p>;

  return (
    <div className="products-page">
      <h1>{t('productsTitle')}</h1>
      <p>{t('productsDescription')}</p>
      <ProductList products={products} />
    </div>
  );
};

export default Products;
