// src/pages/Register.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import RegisterForm from '../../components/RegisterForm/RegisterForm';
import Modal from 'react-modal'
import './Register.css';
import { useTranslation } from 'react-i18next';

const Register = () => {
  const { t } = useTranslation();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const navigate = useNavigate()

  const handleSuccessClose = () => {setShowSuccessModal(false); navigate('/login')};
  const handleErrorClose = () => {setShowErrorModal(false); navigate('/login')};

  return (
    <div className="register-container">
      <h1>{t('createAccount')}</h1>
      <RegisterForm
        setShowSuccessModal={setShowSuccessModal}
        setShowErrorModal={setShowErrorModal}
      />
      
      {/* Success Modal */}
      {showSuccessModal && (
        <Modal
          isOpen={showSuccessModal}
          onRequestClose={handleSuccessClose}
          contentLabel={t('registrationSuccess')}
          className={'modal'}
          overlayClassName={'modal-overlay'}
        >
          <h2>{t('registrationSuccess')}</h2>
          <p>{t('registrationSuccessMessage')}</p>
          <button onClick={handleSuccessClose}>{t('ok')}</button>
        </Modal>
      )}

      {/* Error Modal */}
      {showErrorModal && (
        <Modal
          isOpen={showErrorModal}
          onRequestClose={handleErrorClose}
          contentLabel={t('registrationFailed')}
          className={'modal'}
          overlayClassName={'modal-overlay'}
        >
          <h2>{t('registrationFailed')}</h2>
          <p>{t('registrationFailedMessage')}</p>
          <button onClick={handleErrorClose}>{t('close')}</button>
        </Modal>
      )}
    </div>
  );
};

export default Register;
