import React, { useEffect, useState, useContext } from 'react';
import ProductList from '../../components/ProductList/ProductList';
import axios from 'axios';
import { API_BASE_URL } from '../../config/start.mjs';
import { AuthContext } from '../../context/AuthContext';
import { useTranslation } from 'react-i18next';
import './Home.css'

const Home = () => { 
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [favoriteProducts, setFavoriteProducts] = useState([]);
  const { isAuthenticated, user } = useContext(AuthContext);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/products`);
        const data = response.data;
        console.log(data)
        setProducts(data);
      } catch (error) {
        setError(error.message);
      }
    };

    const fetchFavoriteProducts = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/user/${user._id}/favorites`);
        setFavoriteProducts(response.data);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchProducts();
    if (isAuthenticated) {
      fetchFavoriteProducts();
      setLoading(false)
    } else {
      setLoading(false); // Set loading to false if not authenticated
    }
  }, [isAuthenticated, user]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className='background'>
      <h1>{t('home')}</h1>
      {isAuthenticated && (
        <>
          <h2 className='page-heading'>{t('favoriteProducts')}</h2>
          {favoriteProducts.length > 0 ? (
            <ProductList products={favoriteProducts} />
          ) : (
            <p>{t('noFavoriteProducts')}</p>
          )}
        </>
      )}
      <h2 className='page-heading'>{t('mostRecentProducts')}</h2>
      <ProductList products={products} />
    </div>
  );
};

export default Home;
