// src/components/ColissimoWidget.js

import React, { useEffect } from 'react';
import { getColissimoToken } from '../../utils/auth';  // Adjust import path as necessary

const ColissimoWidget = ({ onAddressSelected }) => {
    useEffect(() => {
      // Define the callback function in the global scope
      window.maMethodeDeCallBack = (point) => {
        console.log('Callback function triggered');
        console.log(point);
        if (typeof onAddressSelected === 'function') {
          onAddressSelected(point);
        } else {
          console.error('onAddressSelected is not a function');
        }
      };
  
      const loadWidget = async () => {
        try {
          const token = await getColissimoToken();
          if (window.jQuery) {
            window.jQuery('#widget-container').frameColissimoOpen({
              URLColissimo: 'https://ws.colissimo.fr',
              callBackFrame: 'maMethodeDeCallBack', // Pass the function name as a string
              ceCountry: 'FR',
              ceAddress: '1 rue de rivoli',
              ceZipCode: '75001',
              ceTown: 'Paris',
              token: token
            });
          } else {
            console.error('jQuery is not available.');
          }
        } catch (error) {
          console.error('Error loading Colissimo Widget:', error);
        }
      };
  
      loadWidget();
  
      return () => {
        if (window.jQuery) {
          window.jQuery('#widget-container').frameColissimoClose();
        }
        // Clean up the global callback function
        delete window.maMethodeDeCallBack;
      };
    }, [onAddressSelected]);
  
    return <div id="widget-container" style={{ height: '400px', width: '100%' }} />;
  };
  

export default ColissimoWidget;
