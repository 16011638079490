import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, CategoryScale, LinearScale, BarElement, LineElement, PointElement } from 'chart.js';
import './WebsiteStatistics.css'; // Ensure you have appropriate CSS for styling
import { API_BASE_URL } from '../../../config/start.mjs';

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement
);

const WebsiteStatistics = () => {
  const [stats, setStats] = useState(null);
  const [error, setError] = useState(null);
    
  useEffect(() => {
    const fetchStatistics = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/admin/stats`);
        setStats(response.data);
      } catch (err) {
        setError('Failed to load statistics.');
      }
    };

    fetchStatistics();
  }, []);
  
  if (error) return <div>{error}</div>;
  if (!stats) return <div>Loading...</div>;
  
  // Prepare chart data
  const revenueData = {
    labels: stats.partnersSalesData.map(partner => partner._id),
    datasets: [
      {
        label: 'Total Revenue (€)',
        data: stats.partnersSalesData.map(partner => partner.totalRevenue),
        backgroundColor: 'rgba(54, 162, 235, 0.5)',
      }
    ]
  };
  
  const salesData = {
    labels: stats.partnersSalesData.map(partner => partner._id),
    datasets: [
      {
        label: 'Total Sales',
        data: stats.partnersSalesData.map(partner => partner.totalSales),
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      }
    ]
  };
  
  return (
    <div className="statistics-page">
      <h1>Website Statistics</h1>

      <div className="stat-item">
        <h2>Total Users</h2>
        <p>{stats.userCount}</p>
      </div>

      <div className="stat-item">
        <h2>Total Products</h2>
        <p>{stats.productCount}</p>
      </div>
      <div className="stat-item">
        <h2>Total Money On Wallets</h2>
        <p>€{stats.totalWalletAmountSum.toFixed(2)}</p>
      </div>
      <div className="stat-item">
        <h2>Total Money Won through Sales</h2>
        <p>€{stats.totalMoneyWonSalesSum.toFixed(2)}</p>
      </div>

      <div className="stat-item">
        <h2>Top 10 Partners with Most Subscribers</h2>
        <ul>
          {stats.partnersWithMostSubscribers.map(partner => (
            <li key={partner._id}>
              {partner.username} - {partner.subscribersCount} subscribers
            </li>
          ))}
        </ul>
      </div>

      <div className="stat-item">
        <h2>Top 10 Products Most Added to Favorites</h2>
        <ul>
          {stats.productsMostFavorites.map(product => (
            <li key={product.productId}>
              {product.productName} - {product.favoritesCount} favorites
            </li>
          ))}
        </ul>
      </div>

      <div className="chart-container">
        <h2>Top Partners by Revenue</h2>
        <Bar
          data={revenueData}
          options={{
            responsive: true,
            plugins: {
              legend: {
                display: true,
                position: 'top',
              },
              tooltip: {
                callbacks: {
                  label: function(tooltipItem) {
                    return `€${tooltipItem.raw.toFixed(2)}`;
                  }
                }
              }
            },
            scales: {
              x: {
                title: {
                  display: true,
                  text: 'Partner',
                }
              },
              y: {
                title: {
                  display: true,
                  text: 'Revenue (€)',
                },
                ticks: {
                  callback: function(value) {
                    return `€${value.toFixed(2)}`;
                  }
                }
              }
            }
          }}
        />
      </div>

      <div className="chart-container">
        <h2>Top Partners by Sales Volume</h2>
        <Bar
          data={salesData}
          options={{
            responsive: true,
            plugins: {
              legend: {
                display: true,
                position: 'top',
              },
              tooltip: {
                callbacks: {
                  label: function(tooltipItem) {
                    return `${tooltipItem.raw} sales`;
                  }
                }
              }
            },
            scales: {
              x: {
                title: {
                  display: true,
                  text: 'Partner',
                }
              },
              y: {
                title: {
                  display: true,
                  text: 'Sales Volume',
                }
              }
            }
          }}
        />
      </div>
    </div>
  );
};

export default WebsiteStatistics;
