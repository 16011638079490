import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './ProductList.css';
import { API_BASE_URL } from '../../config/start.mjs';
import { useTranslation } from 'react-i18next';

const ProductList = ({ products }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [pulseSpeed, setPulseSpeed] = useState('1s'); // Default pulse speed


  const handleProductClick = (product) => {
    navigate(`/viewproduct/${product._id}`);
  };

  const isAuctionEnded = (auctionDate) => {
    const now = new Date();
    const auctionEndDate = new Date(auctionDate);
    return now > auctionEndDate;
  };

  // Countdown logic reused from ViewProduct
  const getTimeRemaining = (endDate) => {
    const auctionEndDate = new Date(endDate);
    
    if (isNaN(auctionEndDate.getTime())) {
      console.error('Invalid auction end date:', endDate);
      return { total: 0, days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const total = auctionEndDate - Date.now();
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    const days = Math.floor(total / (1000 * 60 * 60 * 24));

    return {
      total,
      days,
      hours,
      minutes,
      seconds,
    };
  };

  const Countdown = ({ auctionEndDate }) => {
    const [timeLeft, setTimeLeft] = useState(getTimeRemaining(auctionEndDate));

    useEffect(() => {
      const updateTimer = () => {
        setTimeLeft(getTimeRemaining(auctionEndDate));
      };
      const timeRemaining= getTimeRemaining(auctionEndDate  )
      if (timeRemaining.total <= 60 * 1000) {
        setPulseSpeed('0.25s'); // Less than 1 minute
      } else if (timeRemaining.total <= 60 * 60 * 1000) {
        setPulseSpeed('0.5s'); // Less than 1 hour
      } else {
        setPulseSpeed('1s'); // More than 1 hour
      }
  
      const timerInterval = setInterval(updateTimer, 1000);
      return () => clearInterval(timerInterval);
    }, [auctionEndDate]);

    if (timeLeft.total <= 0) {
      return <></>;
    }

    return (
      <p className="auction-countdown" style={{ animationDuration: pulseSpeed }}>
        {t('timeRemaining')}: {`${timeLeft.days}d ${timeLeft.hours}h ${timeLeft.minutes}m ${timeLeft.seconds}s`}
      </p>
    );
  };

  return (
    <div className='product-list'>
      {products.map(product => (
        <div
          key={product._id} // Assuming _id is used as a unique identifier
          className='product-item'
          onClick={() => handleProductClick(product)}
        >
          <div className='img-container'>
            <img src={`${API_BASE_URL}/${product.images[0]}`} alt={product.name} />
          </div>
          <h2 className='product-list-h2'>{product.name}</h2>
          
          {/* Display bid information */}
          {isAuctionEnded(product.auctionDate) ? (
            <p className='auction-ended'>{t('auctionEnded')}</p>
          ) : (
            <>
              <p className='current-bid'>
                {t('currentBid')}: {product.bid ? `${product.bid.amount} €` : `${product.startingPrice} €`}
              </p>
              {/* Countdown Timer */}
              <Countdown auctionEndDate={product.auctionDate} />
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default ProductList;
