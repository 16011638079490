import React from 'react';
import { useNavigate } from 'react-router-dom';
import './PartnerList.css';
import { API_BASE_URL } from '../../config/start.mjs';

const PartnerList = ({ partners }) => {
  const navigate = useNavigate();

  const handlePartnerClick = (partner) => {
    navigate(`/viewpartner/${partner._id}`);
  };

  return (
    <div className='partner-list'>
      {partners.map(partner => (
        <div 
          key={partner._id} 
          className='partner-item'
          onClick={() => handlePartnerClick(partner)}
        >
          <img 
            src={`${API_BASE_URL}/${partner.profilePicture}`} 
            alt={partner.username} 
            className='partner-profile-picture'
          />
          <h2>{partner.username}</h2>
        </div>
      ))}
    </div>
  );
};

export default PartnerList;
