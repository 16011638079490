import React from 'react';
import './CGU.css';

const CGU = () => {
    return (
        <div className='background'>
            <div className="cgu-container">
                <h1>Conditions Générales d'Utilisation (CGU)</h1>

                    <section>
                        <p>
                            Bienvenue sur LUST AUCTION, un site de vente aux enchères en ligne dédié à la vente de vêtements portés et utilisés par nos modèles partenaires. Nous opérons depuis la France et proposons nos services à une clientèle européenne.
                        </p>
                        <p>
                            Les présentes Conditions Générales d'Utilisation (CGU) ont pour objet de définir les modalités d'accès et d'utilisation du site www.lustauction.fr (ci-après "le Site"). En accédant ou en utilisant notre Site, vous acceptez d'être lié par les présentes CGU. Si vous n'acceptez pas ces conditions, veuillez ne pas utiliser notre Site.
                        </p>
                    </section>

                    <section>
                        <h2>Article 1 – Contacts</h2>
                        <p>
                            En cas de problème avec l'utilisation de ce site , il est possible de contacter les administrateurs via l'adresse email suivante: support@lustauction.fr
                        </p>
                    </section>

                    <section>
                        <h2>Article 2 – Objet du service</h2>
                        <p>
                            Le Site propose une plateforme permettant aux utilisateurs de participer à des enchères en ligne pour acquérir des vêtements portés ou utilisés par des modèles. Ces articles sont proposés par des vendeurs sélectionnés, en partenariat avec LUST AUCTION.
                        </p>
                    </section>

                    <section>
                        <h2>Article 3 – Accès au service</h2>
                        <p>
                            L'accès au Site est gratuit, mais l'inscription est obligatoire pour participer aux enchères. Vous devez être majeur (18 ans et plus) et résider en Europe pour vous inscrire et participer aux enchères.
                        </p>
                    </section>

                    <section>
                        <h2>Article 4 – Inscription et compte utilisateur</h2>
                        <h3>Article 4.1 – Création de compte</h3>
                        <p>
                            Pour participer aux enchères, l'utilisateur doit créer un compte en fournissant des informations exactes et complètes. L'utilisateur est responsable de la confidentialité de ses identifiants et de toute activité sur son compte.
                        </p>
                        <h3>Article 4.2 – Suppression de compte</h3>
                        <p>
                            LUST AUCTION se réserve le droit de suspendre ou de supprimer un compte utilisateur en cas de non-respect des présentes CGU ou de comportement frauduleux.
                        </p>
                    </section>

                    <section>
                        <h2>Article 5 – Processus d'enchères</h2>
                        <h3>Article 5.1 – Participation aux enchères</h3>
                        <p>
                            Les enchères sont ouvertes à tous les utilisateurs inscrits. Chaque enchère a une durée définie et se termine à l'expiration de cette période ou lorsque le prix de réserve est atteint.
                        </p>
                        <h3>Article 5.2 – Validation des enchères</h3>
                        <p>
                            Les enchères sont définitives et obligatoires pour l'utilisateur ayant placé la plus haute offre à la fin de l'enchère. Ce dernier s'engage à payer le montant total de l'enchère gagnée, ainsi que les frais éventuels (frais de livraison, frais de service, etc.).
                        </p>
                        <h3>Article 5.3 – Paiement</h3>
                        <p>
                            Le paiement doit être effectué via les méthodes de paiement sécurisées proposées sur le Site. En cas de non-paiement dans les délais impartis, LUST AUCTION se réserve le droit d'annuler la transaction et de proposer l'article à un autre enchérisseur.
                        </p>
                    </section>

                    <section>
                        <h2>Article 6 – Livraison</h2>
                        <p>
                            LUST AUCTION s'engage à livrer les articles gagnés dans les meilleurs délais après réception du paiement. Les frais de livraison sont à la charge de l'acheteur, sauf indication contraire.
                        </p>
                    </section>

                    <section>
                        <h2>Article 7 – Garantie et authenticité des articles</h2>
                        <p>
                            Tous les articles proposés sur LUST AUCTION sont accompagnés d'un certificat d'authenticité garantissant leur origine et leur utilisation par la célébrité mentionnée. LUST AUCTION met tout en œuvre pour garantir l'authenticité des articles, mais décline toute responsabilité en cas de litige sur la provenance ou l'authenticité des produits, sous réserve de la preuve contraire.
                        </p>
                    </section>

                    <section>
                        <h2>Article 8 – Droit de rétractation</h2>
                        <p>
                            Les ventes effectuées sur le site LUST AUCTION s'inscrivent dans un cadre de transactions entre particuliers. Conformément à la réglementation en vigueur concernant les ventes de particulier à particulier, le droit de rétractation de 14 jours prévu pour les consommateurs ne s'applique pas.
                        </p>
                        <p>
                            En conséquence, les utilisateurs ne bénéficient d'aucun droit de rétractation après la finalisation de l'enchère et la réception de l'article, sauf accord préalable express entre le vendeur et l'acheteur, ou en cas de vice caché. Les utilisateurs sont donc invités à poser toutes les questions nécessaires avant de placer une enchère définitive.
                        </p>
                    </section>

                    <section>
                        <h2>Article 9 – Responsabilité</h2>
                        <p>
                            LUST AUCTION s'efforce de fournir un service de qualité mais décline toute responsabilité en cas de dysfonctionnements du site, de perturbations liées à l'enchère ou de retard dans la livraison des articles. En cas de force majeure, LUST AUCTION ne pourra être tenu responsable.
                        </p>
                    </section>

                    <section>
                        <h2>Article 10 – Propriété intellectuelle</h2>
                        <p>
                            Tout le contenu du Site (textes, images, logos, etc.) est la propriété exclusive de LUST AUCTION ou de ses partenaires. Toute reproduction, utilisation ou distribution du contenu sans autorisation écrite préalable est interdite.
                        </p>
                    </section>

                    <section>
                        <h2>Article 11 – Protection des données personnelles</h2>
                        <p>
                            LUST AUCTION s'engage à protéger les données personnelles des utilisateurs conformément à la réglementation européenne en vigueur (RGPD). Les informations collectées sont utilisées uniquement dans le cadre de la gestion des comptes, des enchères et de la livraison des articles.
                        </p>
                    </section>

                    <section>
                        <h2>Article 12 – Modifications des CGU</h2>
                        <p>
                            LUST AUCTION se réserve le droit de modifier les présentes CGU à tout moment. Les utilisateurs seront informés des modifications par e-mail ou lors de leur prochaine connexion au Site. L'utilisation continue du Site après notification des modifications vaut acceptation des nouvelles CGU.
                        </p>
                    </section>

                    <section>
                        <h2>Article 13 – Droit applicable et juridiction compétente</h2>
                        <p>
                            Les présentes CGU sont régies par le droit français. En cas de litige, les parties s'engagent à rechercher une solution amiable avant d'engager toute action en justice. À défaut d'accord, les tribunaux compétents seront ceux du ressort de la Cour d'appel d’Aix-en-Provence.
                        </p>
                    </section>

                    <section>
                        <h2>Article 14 – Contact</h2>
                        <p>
                            Pour toute question ou demande d'information concernant les présentes CGU, vous pouvez contacter notre service client à l'adresse suivante : support@lustaction.fr.
                        </p>
                    </section>

                    <p>
                        Nous vous remercions d'avoir choisi LUST AUCTION et vous souhaitons une expérience enrichissante sur notre plateforme de vente aux enchères exclusive.
                    </p>
            </div>
        </div>
    );
};

export default CGU;
