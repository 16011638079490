import React from 'react';
import { useTranslation } from 'react-i18next';
import './CountryDropdown.css'; // Import the CSS styles for the dropdown

const countries = ['France'];

const CountryDropdown = ({ selectedCountry, setSelectedCountry }) => {
  const { t } = useTranslation();

  return (
    <div className="dropdown-container">
      <label className="dropdown-label">{t('country')}</label>
      <select
        value={selectedCountry}
        onChange={(e) => setSelectedCountry(e.target.value)}
        required
        className="dropdown-select"
      >
        <option value="" disabled>{t('selectCountry')}</option>
        {countries.map((country) => (
          <option key={country} value={country}>
            {country}
          </option>
        ))}
      </select>
    </div>
  );
};

export default CountryDropdown;
