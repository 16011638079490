import React, { useState, useEffect, useContext } from "react";
import { API_BASE_URL } from "../../config/start.mjs";
import { AuthContext } from "../../context/AuthContext";
import axios from "axios";
import "./Partners.css";
import PartnerList from "../../components/PartnerList/PartnerList";
import { useTranslation } from 'react-i18next';


const Partners = () => {
  const [partners, setPartners] = useState([]);
  const [loadingPartners, setLoadingPartners] = useState(true);
  const [subscribedPartners, setSubscribedPartners] = useState([]);
  const [error, setError] = useState(null);
  const { isAuthenticated, user } = useContext(AuthContext);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchPartners = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/partner`);
        setPartners(response.data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoadingPartners(false);
      }
    };

    const fetchSubscribedPartners = async () => {
      if (user) { // Check if user is defined
        try {
          const response = await axios.get(`${API_BASE_URL}/api/user/${user._id}/subscriptions`);
          setSubscribedPartners(response.data);
        } catch (error) {
          setError(error.message);
        }
      }
    };
  
    fetchPartners();
    fetchSubscribedPartners();
  }, [user]);

  return (
    <div className="partner-container">
      <div className="content">
        {isAuthenticated && (
          <>
            <h2 className='page-heading'>{t('subscribedPartners')}</h2>
            {subscribedPartners.length > 0 ? (
              <PartnerList partners={subscribedPartners} />
            ) : (
              <p>{t('noSubscribedPartners')}</p>
            )}
          </>
        )}
        <h1>{t('ourPartners')}</h1>
        {loadingPartners ? (
          <p>{t('loading')}</p>
        ) : error ? (
          <p>{t('error')}: {error}</p>
        ) : (
          <PartnerList partners={partners} />
        )}
      </div>
    </div>
  );
};

export default Partners;
