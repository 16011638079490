// src/components/ProductImageSlider.jsx

import React, { useState, useRef, useEffect } from 'react';
import { API_BASE_URL } from '../../config/start.mjs';
import './ProductImageSlider.css'; // Import your CSS file

const ProductImageSlider = ({ images, name }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const imageContainerRef = useRef(null);

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    }
  };

  const handleNext = () => {
    if (currentIndex < images.length - 1) {
      setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, images.length - 1));
    }
  };

  // Adjust scroll position based on the current index
  useEffect(() => {
    if (imageContainerRef.current) {
      imageContainerRef.current.style.transform = `translateX(-${currentIndex * 100}%)`;
    }
  }, [currentIndex]);

  // Determine whether to show navigation arrows
  const showArrows = images.length > 1;
  const showLeftArrow = showArrows && currentIndex > 0;
  const showRightArrow = showArrows && currentIndex < images.length - 1;

  return (
    <div className="product-image-container">
      {showLeftArrow && (
        <button className="arrow arrow-left" onClick={handlePrev}>&lt;</button>
      )}
      <div className="product-image" ref={imageContainerRef}>
        {images.map((image, index) => (
          <img
            key={index}
            src={`${API_BASE_URL}/${image}`}
            alt={`${name} - ${index + 1}`}
          />
        ))}
      </div>
      {showRightArrow && (
        <button className="arrow arrow-right" onClick={handleNext}>&gt;</button>
      )}
    </div>
  );
};

export default ProductImageSlider;
