import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_BASE_URL } from '../../../config/start.mjs';
import './ViewDelivery.css';
import { useTranslation } from 'react-i18next';

const ViewDelivery = () => {
  const [delivery, setDelivery] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { deliveryId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchDelivery = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/admin/deliveries/${deliveryId}`, {
          headers: {
            'Content-Type': 'application/json',
          }
        });
        if (response.status === 200) {
          setDelivery(response.data);
        }
      } catch (error) {
        console.error('Error fetching delivery:', error);
        setError('Failed to fetch delivery details.');
      } finally {
        setLoading(false);
      }
    };

    fetchDelivery();
  }, [deliveryId]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p className="error-message">{error}</p>;

  if (!delivery) return <p>{t('noDeliveryFound')}</p>;

  return (
    <div className="view-delivery">
      <div className='admin-back-button-container'>
        <button className="back-button" onClick={() => navigate(-1)}>{t('back')}</button>
      </div>
      <h1>{t('deliveryDetails')}</h1>
      <div className="delivery-details">
        <p><strong>{t('productName')}:</strong> {delivery.product.name}</p>
        <p><strong>{t('productId')}:</strong> {delivery.product.id}</p>
        <p><strong>{t('winner')}:</strong> {delivery.winner.username}</p>
        <p><strong>{t('winnerEmail')}:</strong> {delivery.winner.email}</p>
        <p><strong>{t('deliveryDate')}:</strong> {new Date(delivery.date).toLocaleDateString()}</p>
        <p><strong>{t('shippingFees')}:</strong> {delivery.shippingFees} €</p>


        <h2>{t('shippingDetails')}</h2>
        <p><strong>{t('shippingType')}:</strong> {delivery.shippingAddress.type}</p>
        <p><strong>{t('addressName')}:</strong> {delivery.shippingAddress.lastName}</p>
        <p><strong>{t('address')}:</strong> 
          {delivery.shippingAddress.addressName}, {delivery.shippingAddress.address1}
          {delivery.shippingAddress.address2 ? `, ${delivery.shippingAddress.address2}` : ''}
          {delivery.shippingAddress.address3 ? `, ${delivery.shippingAddress.address3}` : ''}
          {delivery.shippingAddress.postalCode}, {delivery.shippingAddress.city}, {delivery.shippingAddress.country}
        </p>
        <p><strong>{t('phoneNumber')}:</strong> {delivery.shippingAddress.phoneNumber}</p>

        {delivery.shippingAddress.type === 'Relay-Point' && (
          <>
            <p><strong>{t('relayPointId')}:</strong> {delivery.shippingAddress.identifier}</p>
            <p><strong>{t('relayPointName')}:</strong> {delivery.shippingAddress.relayPointName}</p>
          </>
        )}
        <p><strong>{t('status')}:</strong> {delivery.status}</p>
      </div>
    </div>
  );
};

export default ViewDelivery;
