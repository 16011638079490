// src/pages/PartnerDashboard/Sidebar/Sidebar.js
import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './Sidebar.css';

const Sidebar = () => {
  const { t } = useTranslation();

  return (
    <div className="sidebar">
      <nav>
        <ul>
          <li>
            <NavLink to="/partner/dashboard/profile" className={({ isActive }) => (isActive ? 'active' : 'inactive')}>
              {t('sidebar.profileManagement')}
            </NavLink>
          </li>
          <li>
            <NavLink to="/partner/dashboard/statistics" className={({ isActive }) => (isActive ? 'active' : 'inactive')}>
              {t('sidebar.statistics')}
            </NavLink>
          </li>
          <li>
            <NavLink to="/partner/dashboard/products" className={({ isActive }) => (isActive ? 'active' : 'inactive')}>
              {t('sidebar.products')}
            </NavLink>
          </li>
          <li>
            <NavLink to="/partner/dashboard/sales-history" className={({ isActive }) => (isActive ? 'active' : 'inactive')}>
              {t('sidebar.salesHistory')}
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
