import React, { useState } from 'react';
import './ShippingAddressForm.css'; // Import the CSS styles
import CountryDropdown from '../CountryDropdown/CountryDropdown'; // Import the CountryDropdown component

const ShippingAddressForm = ({
  shippingAddress,
  setShippingAddress,
  handleShippingAddressSubmit,
  handleAddressNameChange,
  addressError,
  t
}) => {
  const [countryError, setCountryError] = useState('');

  // Helper function to validate postal code
  const isValidPostalCode = (postalCode) => {
    return /^\d{5}$/.test(postalCode); // Example validation for a 5-digit postal code
  };

  // Helper function to validate phone number
  const isValidPhoneNumber = (phoneNumber) => {
    return /^\+?[0-9]{1,15}$/.test(phoneNumber); // Allows an optional '+' followed by 1-15 digits
  };

  // Function to handle form submission
  const onSubmit = (e) => {
    e.preventDefault();
    
    // Clear previous country error
    setCountryError('');

    // Additional validation checks before submitting
    if (!isValidPostalCode(shippingAddress.postalCode)) {
      // Set an error or handle invalid postal code
      return;
    }

    if (!isValidPhoneNumber(shippingAddress.phoneNumber)) {
      // Set an error or handle invalid phone number
      return;
    }

    if (!shippingAddress.country) {
      // Set error if no country is selected
      setCountryError(t('countryRequired'));
      return;
    }

    // Call the actual submit handler if validation passes
    handleShippingAddressSubmit(e);
  };

  return (
    <form onSubmit={onSubmit} className="shipping-address-form">
      <div>
        <label>{t('addressName')}</label>
        <input
          type="text"
          value={shippingAddress.addressName}
          onChange={handleAddressNameChange}
          required
          maxLength={50} // Limit to 50 characters
        />
        {addressError && <p className="error-message">{addressError}</p>}
      </div>
      <div>
        <label>{t('name')}</label>
        <input
          type="text"
          value={shippingAddress.lastName}
          onChange={(e) => setShippingAddress({ ...shippingAddress, lastName: e.target.value })}
          required
          maxLength={30} // Limit to 30 characters
        />
      </div>
      <div>
        <label>{t('firstName')}</label>
        <input
          type="text"
          value={shippingAddress.firstName}
          onChange={(e) => setShippingAddress({ ...shippingAddress, firstName: e.target.value })}
          required
          maxLength={30} // Limit to 30 characters
        />
      </div>
      <div>
        <label>{t('address')}</label>
        <input
          type="text"
          value={shippingAddress.address1}
          onChange={(e) => setShippingAddress({ ...shippingAddress, address1: e.target.value })}
          required
          maxLength={100} // Limit to 100 characters
        />
      </div>
      <div>
        <label>{t('postalCode')}</label>
        <input
          type="text"
          value={shippingAddress.postalCode}
          onChange={(e) => {
            const value = e.target.value;
            // Allow only numbers and limit to 5 characters
            if (/^\d{0,5}$/.test(value)) {
              setShippingAddress({ ...shippingAddress, postalCode: value });
            }
          }}
          required
        />
        {!isValidPostalCode(shippingAddress.postalCode) && (
          <p className="error-message">{t('invalidPostalCode')}</p>
        )}
      </div>
      <div>
        <label>{t('city')}</label>
        <input
          type="text"
          value={shippingAddress.city}
          onChange={(e) => setShippingAddress({ ...shippingAddress, city: e.target.value })}
          required
          maxLength={50} // Limit to 50 characters
        />
      </div>
      <CountryDropdown
        selectedCountry={shippingAddress.country}
        setSelectedCountry={(country) => setShippingAddress({ ...shippingAddress, country })}
      />
      {countryError && <p className="error-message">{countryError}</p>}
      <div>
        <label>{t('phoneNumber')}</label>
        <input
          type="tel"
          value={shippingAddress.phoneNumber}
          onChange={(e) => {
            const value = e.target.value;
            // Allow only valid phone number characters and limit to 15 characters
            if (/^\+?[0-9]{0,15}$/.test(value)) {
              setShippingAddress({ ...shippingAddress, phoneNumber: value });
            }
          }}
          required
          maxLength={15} // Limit to 15 characters
        />
        {!isValidPhoneNumber(shippingAddress.phoneNumber) && (
          <p className="error-message">{t('invalidPhoneNumber')}</p>
        )}
      </div>
      <button type="submit" className="modal-button submit-button">
        {t('submit')}
      </button>
    </form>
  );
};

export default ShippingAddressForm;
