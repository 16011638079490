import React, { useState, useEffect, useContext, useCallback } from 'react';
import axios from 'axios';
import { AuthContext } from '../../../context/AuthContext';
import { Line, Bar } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, CategoryScale, LinearScale, BarElement, LineElement, PointElement } from 'chart.js';
import { API_BASE_URL } from '../../../config/start.mjs';
import { useTranslation } from 'react-i18next';

import './Statistics.css'; // Ensure you have appropriate CSS for styling

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement
);

const Statistics = () => {
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();

  const [totalSales, setTotalSales] = useState(0);
  const [salesByDate, setSalesByDate] = useState({});
  const [topBuyers, setTopBuyers] = useState([]);
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });

  const processSellHistory = useCallback((data) => {
    const generateChartData = (sales) => {
      const labels = Object.keys(sales);
      const data = labels.map(label => sales[label].total);
      return {
        labels,
        datasets: [
          {
            label: t('salesOverTime'),
            data,
            borderColor: 'rgba(75, 192, 192, 1)',
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            fill: true,
          }
        ],
      };
    };

    const getTopBuyers = (data) => {
      const buyerCounts = data.reduce((acc, sale) => {
        const id = sale.winnerId ? sale.winnerId.username : 'DeletedAccount';
        if (!acc[id]) {
          acc[id] = 0;
        }
        acc[id] += 1;
        return acc;
      }, {});
  
      return Object.entries(buyerCounts)
        .sort(([, a], [, b]) => b - a)
        .map(([id, count]) => ({ id, count }));
      }

    const sales = data.reduce((acc, sale) => {
      const date = new Date(sale.date);
      const key = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
      if (!acc[key]) {
        acc[key] = { total: 0, sales: [] };
      }
      acc[key].total += sale.price;
      acc[key].sales.push(sale);
      return acc;
    }, {});

    setSalesByDate(sales);
    setTotalSales(data.reduce((sum, sale) => sum + sale.price, 0));
    setTopBuyers(getTopBuyers(data));
    setChartData(generateChartData(sales));
  }, [t]);

  useEffect(() => {
    const fetchSellHistory = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/partner/sellhistory/statistics/${user._id}`);
        const data = response.data;
        console.log(data)
        processSellHistory(data);
      } catch (error) {
        console.error('Error fetching sell history:', error);
      }
    };

    fetchSellHistory();
  }, [user, processSellHistory]);

  // Chart options for Bar chart
  const barOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: function(tooltipItem) {
            return `${tooltipItem.label}: €${tooltipItem.raw.toFixed(2)}`;
          }
        }
      }
    },
    scales: {
      x: {
        title: {
          display: true,
          text: t('date'),
        }
      },
      y: {
        title: {
          display: true,
          text: t('amount'),
        },
        ticks: {
          callback: function(value) {
            return `€${value.toFixed(2)}`;
          }
        }
      }
    }
  };

  // Chart options for Line chart
  const lineOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: function(tooltipItem) {
            return `€${tooltipItem.raw.toFixed(2)}`;
          }
        }
      }
    },
    scales: {
      x: {
        title: {
          display: true,
          text: t('date'),
        }
      },
      y: {
        title: {
          display: true,
          text: t('amount'),
        },
        ticks: {
          callback: function(value) {
            return `€${value.toFixed(2)}`;
          }
        }
      }
    }
  };

  return (
    <div className="statistics">
      <h1>{t('statisticsTitle')}</h1>
      <div className="statistics-summary">
        <div className="total-sales">
          <h2>{t('totalSales')}: €{totalSales.toFixed(2)}</h2>
        </div>
        <div className="total-subscribers">
          <h2>{t('totalSubscribers')}: {user.subscribers.length}</h2>
        </div>
        <div className="sales-by-date">
          <h2>{t('salesBreakdown')}</h2>
          <Bar
            data={{
              labels: Object.keys(salesByDate),
              datasets: [
                {
                  label: t('sales'),
                  data: Object.values(salesByDate).map(item => item.total),
                  backgroundColor: 'rgba(75, 192, 192, 0.5)',
                }
              ]
            }}
            options={barOptions}
          />
        </div>
        <div className="top-buyers">
          <h2>{t('topBuyers')}</h2>
          <div className="buyers-list">
            {topBuyers.map((buyer, index) => (
              <div key={index} className="buyer-item">
                <span className="buyer-name">{buyer.id}</span>
                <span className="buyer-count">{buyer.count} {t('purchases')}</span>
              </div>
            ))}
          </div>
        </div>
        <div className="sales-trend">
          <h2>{t('salesTrend')}</h2>
          <Line data={chartData} options={lineOptions} />
        </div>
      </div>
    </div>
  );
};

export default Statistics;
