import React, { useEffect, useContext, useState } from 'react';
import './History.css'; // Import the CSS file
import axios from 'axios';
import { API_BASE_URL } from '../../config/start.mjs';
import { AuthContext } from '../../context/AuthContext';
import BidHistoryList from '../../components/BidHistoryList/BidHistoryList'; 
import WinHistoryList from '../../components/WinHistoryList/WinHistoryList';
import { useTranslation } from 'react-i18next';


const History = () => {
    const { user } = useContext(AuthContext);
    const [initialLoad, setInitialLoad] = useState(true);
    const [bidHistory, setBidHistory] = useState([]);
    const [winHistory, setWinHistory] = useState([]);
    const { t } = useTranslation();


    useEffect(() => {
        const fetchUserHistory = async (userId) => {
            try {
                const response = await axios.get(`${API_BASE_URL}/api/user/${userId}/history`);
                console.log(response.data);
                setBidHistory(response.data.bidHistory);
                setWinHistory(response.data.winHistory);
                setInitialLoad(false);
            } catch (error) {
                console.error('Error fetching user history:', error);
            }
        };

        if (initialLoad && user && user._id) {
            fetchUserHistory(user._id);
        }
    }, [user, initialLoad]);

    return (
        <div className="history-page-container">
            <div className="history-container">
                <h2>{t('auctionsWon')}</h2>
                {winHistory.length > 0 ? (
                    <WinHistoryList winHistory={winHistory.reverse()} />
                ) : (
                    <p>{t('noWinsFound')}</p>
                )}
                <h2>{t('bidHistory')}</h2>
                {bidHistory.length > 0 ? (
                    <BidHistoryList bidHistory={bidHistory.reverse()} />
                ) : (
                    <p>{t('noBidsFound')}</p>
                )}
            </div>
        </div>
    );
};

export default History;
