import React, { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';
import { useTranslation } from 'react-i18next';

import { API_BASE_URL } from '../../config/start.mjs';

const VerifyEmail = () => {
  const { token } = useParams();
  const { setUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/verifyEmail/${token}`);
        if (response.status === 200) {
          setUser((prevUser) => ({
            ...prevUser,
            isVerified: true
          }));
          setShowSuccessModal(true);
        } else {
          throw new Error(response.data.message || 'Verification failed');
        }
      } catch (error) {
        console.error('Verification failed:', error.response ? error.response.data : error.message);
        setShowErrorModal(true);
      }
    };

    verifyEmail();
  }, [token, navigate, setUser]);

  const handleSuccessClose = () => {
    setShowSuccessModal(false);
    navigate('/login');
  };

  const handleErrorClose = () => {
    setShowErrorModal(false);
    navigate('/register');
  };

  return (
    <div className="verify-email-container">
      <h1>{t('verifyingEmail')}</h1>

      {/* Success Modal */}
      {showSuccessModal && (
        <Modal
          isOpen={showSuccessModal}
          onRequestClose={handleSuccessClose}
          contentLabel={t('emailVerified')}
          className={'modal'}
          overlayClassName={'modal-overlay'}
        >
          <h2>{t('emailVerified')}</h2>
          <p>{t('emailVerifiedMessage')}</p>
          <button onClick={handleSuccessClose}>{t('ok')}</button>
        </Modal>
      )}

      {/* Error Modal */}
      {showErrorModal && (
        <Modal
          isOpen={showErrorModal}
          onRequestClose={handleErrorClose}
          contentLabel={t('verificationFailed')}
          className={'modal'}
          overlayClassName={'modal-overlay'}
        >
          <h2>{t('verificationFailed')}</h2>
          <p>{t('verificationFailedMessage')}</p>
          <button onClick={handleErrorClose}>{t('close')}</button>
        </Modal>
      )}
    </div>
  );
};

export default VerifyEmail;
