import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import './WinHistoryList.css'; // Ensure this file includes the necessary styles

const WinHistoryList = ({ winHistory }) => {
    const { t } = useTranslation();
    const navigate = useNavigate(); // Initialize the useNavigate hook

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5; // Number of items to show per page

    // Calculate the index of the last item on the current page
    const lastIndex = currentPage * itemsPerPage;
    // Calculate the index of the first item on the current page
    const firstIndex = lastIndex - itemsPerPage;
    // Slice the items array to get the current page items
    const currentWins = winHistory.slice(firstIndex, lastIndex);
    // Calculate the total number of pages
    const totalPages = Math.ceil(winHistory.length / itemsPerPage);

    // Function to handle page change
    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    // Function to handle item click
    const handleItemClick = (productId) => {
        navigate(`/viewproduct/${productId}`); // Navigate to the view product page
    };

    return (
        <div>
            <ul className="win-history-list">
                {[...currentWins].reverse().map((entry, index) => (
                    <li
                        key={index}
                        className="win-history-item"
                        onClick={() => handleItemClick(entry.productId)} // Handle item click
                        style={{ cursor: 'pointer' }} // Change cursor to pointer on hover
                    >
                        <p><strong>{t('product')}:</strong> {entry.productName}</p>
                        <p><strong>{t('paidAmount')}:</strong> {entry.paidAmount}€</p>
                        <p><strong>{t('winDate')}:</strong> {new Date(entry.winDate).toLocaleString()}</p>
                    </li>
                ))}
            </ul>
            {winHistory.length > itemsPerPage && (
                <div className="pagination">
                    <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                        {t('previous')}
                    </button>
                    <span>{t('page')} {currentPage} {t('of')} {totalPages}</span>
                    <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                        {t('next')}
                    </button>
                </div>
            )}
        </div>
    );
};

export default WinHistoryList;
