import React, {useContext} from 'react';
import { AuthContext } from '../../context/AuthContext'; 

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './Footer.css';

const Footer = () => {
  const { t } = useTranslation();
  const {isAuthenticated} = useContext(AuthContext)

  return (
    <footer>
      <div className="footer-container">
        <p className="footer-text">{t('copyright')}</p>
        <nav className="footer-nav">
          <Link className="footer-link" to="/">{t('home')}</Link>
          <Link className="footer-link" to="/partners">{t('partners')}</Link>
          <Link className="footer-link" to="/cgu">{t('cgu')}</Link>
          <Link className="footer-link" to="/becomepartner">{t('becomePartner')}</Link>
          {isAuthenticated?  <> </>: <Link className="footer-link" to="/login">{t('login')}</Link>}
        </nav>
      </div>
    </footer>
  );
};
 
export default Footer;
