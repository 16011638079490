import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import './BidHistoryList.css'; // Ensure this file includes the necessary styles

const BidHistoryList = ({ bidHistory }) => {
    const { t } = useTranslation();
    const navigate = useNavigate(); // Initialize the useNavigate hook

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;

    // Calculate the index of the last item on the current page
    const lastIndex = currentPage * itemsPerPage;
    // Calculate the index of the first item on the current page
    const firstIndex = lastIndex - itemsPerPage;
    // Slice the items array to get the current page items
    const currentBids = bidHistory.slice(firstIndex, lastIndex);
    // Calculate the total number of pages
    const totalPages = Math.ceil(bidHistory.length / itemsPerPage);

    // Function to handle page change
    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    // Function to handle item click
    const handleItemClick = (productId) => {
        navigate(`/viewproduct/${productId}`); // Navigate to the view product page
    };

    return (
        <div>
            <ul className="bid-history-list">
                {[...currentBids].map((bid, index) => (
                    <li
                        key={index}
                        className="bid-history-item"
                        onClick={() => handleItemClick(bid.productId)} // Handle item click
                        style={{ cursor: 'pointer' }} // Change cursor to pointer on hover
                    >
                        <p><strong>{t('product')}:</strong> {bid.productName}</p>
                        <p><strong>{t('bidAmount')}:</strong> {bid.bidAmount}€</p>
                        <p><strong>{t('bidDate')}:</strong> {new Date(bid.bidDate).toLocaleString()}</p>
                    </li>
                ))}
            </ul>
            <div className="pagination">
                <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                    {t('previous')}
                </button>
                <span>{t('page')} {currentPage} {t('of')} {totalPages}</span>
                <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                    {t('next')}
                </button>
            </div>
        </div>
    );
};

export default BidHistoryList;
