import React, { useContext, useState } from 'react';
import { AuthContext } from '../../../context/AuthContext';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { API_BASE_URL } from '../../../config/start.mjs';
import './ProfileManagement.css';
import Modal from '../../../components/Modal/Modal';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const ProfileManagement = () => {
  const { user, setUser } = useContext(AuthContext);
  const { t } = useTranslation();

  const [description, setDescription] = useState(user.description || '');
  const [profilePicture, setProfilePicture] = useState(user.profilePicture || '');
  const [otherImages, setOtherImages] = useState(user.otherImages || []);
  const [imagesToRemove, setImagesToRemove] = useState([]);
  const [imagesToAdd, setImagesToAdd] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [success, setSuccess] = useState(false);

  const handleResetProfilePicture = () => {
    setProfilePicture(user.profilePicture || '');
  };

  const handleResetOtherImages = () => {
    setOtherImages(user.otherImages || []);
  };

  const handleResetDescription = () => {
    setDescription(user.description || '');
  };

  const handleRemoveImage = (image) => {
    setImagesToRemove([...imagesToRemove, image]);
    setOtherImages(otherImages.filter(img => img !== image));
  };

  const handleAddImages = (e) => {
    setOtherImages([...otherImages, ...Array.from(e.target.files)]);
    setImagesToAdd([...imagesToAdd, ...Array.from(e.target.files)]);
    console.log(otherImages, "HUJBCI", imagesToAdd)
  };

  const handleProfileUpdate = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    const formData = new FormData();
    formData.append('description', description);

    // Append profile picture only if it's a new file
    if (profilePicture && typeof profilePicture !== 'string') {
        formData.append('profilePicture', profilePicture);
    }

    // Separate new files from existing images in 'otherImages'
    otherImages.forEach((image) => {
        if (typeof image !== 'string') {
            // If it's a new file (not a string), append to FormData
            formData.append('otherImages', image);
        }
    });

    // Send image paths to be retained
    const imagePaths = otherImages
        .filter((image) => typeof image === 'string'); // Keep only the strings (paths)
    formData.append('existingImagePaths', JSON.stringify(imagePaths));

    formData.append('imagesToRemove', JSON.stringify(imagesToRemove));

    try {
        const response = await axios.patch(`${API_BASE_URL}/api/partner/update-profile`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            withCredentials: true,
        });

        setUser((prevUser) => ({
            ...prevUser,
            profilePicture: response.data.profilePicture,
            otherImages: response.data.otherImages,
            description: response.data.description,
        }));
        console.log('Profile updated successfully', response.data.message);
        setModalMessage('successfulUpdate');
        setSuccess(true);
        setShowModal(true);
    } catch (error) {
        console.error('Error updating profile:', error);
        setModalMessage('errorUpdate');
        setSuccess(false);
        setShowModal(true);
    } finally {
        setIsLoading(false);
    }
};

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedImages = Array.from(otherImages);
    const [movedImage] = reorderedImages.splice(result.source.index, 1);
    reorderedImages.splice(result.destination.index, 0, movedImage);

    setOtherImages(reorderedImages);
  };

  const renderImagePreview = (image, type) => {
    const className = type === "profile" ? "" : "other-image";
    // Check if image is a string, and if so, include API_BASE_URL
    const imageUrl = typeof image === 'string'
      ? `${API_BASE_URL}/${image}`
      : URL.createObjectURL(image);
  
    return (
      <div className={className}>
        <img
          src={imageUrl}
          alt="Preview"
          className={className}
        />
        {type === 'other' && (
          <button type="button" onClick={() => handleRemoveImage(image)}>
            {"x"}
          </button>
        )}
      </div>
    );
  };
  

  return (
    <div className="profile-management">
      <div className="profile-preview">
        <h2>{t('profilePreview')}</h2>
        {profilePicture && (
          <div className="profile-picture">
            {renderImagePreview(profilePicture, 'profile')}
          </div>
        )}
        <h1>{user.username}</h1>
        {otherImages.length > 0 && (
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="images">
              {(provided) => (
                <div
                  className="other-images"
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {otherImages.map((image, index) => (
                    <Draggable
                      key={image.name || image}
                      draggableId={image.name || image}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="draggable-image"
                        >
                          {renderImagePreview(image, 'other')}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
        {description && (
          <div className="description-preview">
            <h3 className="about-me-heading">{t('aboutMe')}</h3>
            <p>{description}</p>
          </div>
        )}
      </div>

      <form style={{ marginTop: '20px' }} onSubmit={handleProfileUpdate}>
        <div className="form-group">
          <label>{t('profilePicture')}</label>
          <input
            type="file"
            onChange={(e) => setProfilePicture(e.target.files[0])}
          />
          <button type="button" onClick={handleResetProfilePicture}>
            {t('resetProfilePicture')}
          </button>
        </div>
        <div className="form-group">
          <label>{t('otherImages')}</label>
          <input
            type="file"
            multiple
            onChange={handleAddImages}
          />
          <button type="button" onClick={handleResetOtherImages}>
            {t('resetOtherImages')}
          </button>
        </div>
        <div className="form-group">
          <label>{t('description')}</label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder={t('writeDescriptionHere')}
          />
          <button type="button" onClick={handleResetDescription}>
            {t('resetDescription')}
          </button>
        </div>
        <div className='form-group'>
          <button
            type="submit"
            className={`update-profile-button ${isLoading ? 'disabled' : ''}`}
            style={{ color: 'white' }}
            disabled={isLoading}
          >
            {isLoading ? (
              <>
                <span className="spinner"></span> {t('updating')}
              </>
            ) : (
              t('updateProfile')
            )}
          </button>
        </div>
      </form>

      <Modal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        message={modalMessage}
        success={success}
      />
    </div>
  );
};

export default ProfileManagement;
