import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_BASE_URL } from '../../../config/start.mjs';
import { useTranslation } from 'react-i18next';

import '../../ViewPartner/ViewPartner.css';  // Reuse the same CSS as ViewPartner

const PartnerApplicationProcess = () => {
  const { applicationId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [application, setApplication] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [profilePicture, setProfilePicture] = useState('');
  const [otherImages, setOtherImages] = useState([]);
  const [description, setDescription] = useState('');

  useEffect(() => {
    const fetchApplication = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/admin/partner/application/${applicationId}`);
        if (response.status === 200) {
          setApplication(response.data);
          setProfilePicture(response.data.images[0]);  // Default to the first image
          setOtherImages(response.data.images.slice(1));
          setDescription(response.data.post)  // Use the remaining images
        }
      } catch (error) {
        console.error('Error fetching application details:', error);
        setError('Failed to fetch application details.');
      } finally {
        setLoading(false);
      }
    };

    fetchApplication();
  }, [applicationId]);

  const handleProfilePictureChange = (e) => {
    setProfilePicture(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleImageSelection = (image) => {
    if (otherImages.includes(image)) {
      setOtherImages(otherImages.filter((img) => img !== image));
    } else {
      setOtherImages([...otherImages, image]);
    }
  };

  const handleSubmit = async () => {
    try {
      const payload = {
        profilePicture,
        otherImages,
        description,
      };
      console.log(payload.description)
      const response = await axios.post(`${API_BASE_URL}/api/admin/partner/application/${applicationId}/confirm`, payload);
      if (response.status === 200) {
        alert('Application processed successfully!');
        navigate(-1);  // Go back to the previous page
      } else {
        alert('Failed to process application.');
      }
    } catch (error) {
      console.error('Error submitting application:', error);
      alert('Error submitting application.');
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="partner-profile background">
      <div className='back-button-container'>
        <button className="back-button" onClick={() => navigate(-1)}>Back</button>
      </div>
      <div className='partner-info'>
      {application.images.length > 0 && (
        <div className="profile-picture">
          <h3>Select Profile Picture</h3>
          <select value={profilePicture} onChange={handleProfilePictureChange}>
            {application.images.map((image, index) => (
              <option key={index} value={image}>
                Image {index + 1}
              </option>
            ))}
          </select>
          <img src={`${API_BASE_URL}/${profilePicture}`} alt="Selected Profile" />
        </div>
      )}

      <h1>{application.userId}'s Preview</h1>

      {/* Description Input */}
      <div className="partner-description">
        <h3>About Me</h3>
        <textarea
          value={description}
          onChange={handleDescriptionChange}
          placeholder={application.post}
          rows="4"
          style={{ width: '100%' }}
        />
      </div>

      {/* Other Images Selection */}
      <div className="other-images">
        <h3>Select Other Images</h3>
        {application.images.map((image, index) => (
          <div key={index} className="image-selection">
            <input
              type="checkbox"
              id={`image-${index}`}
              value={image}
              checked={otherImages.includes(image)}
              onChange={() => handleImageSelection(image)}
            />
            <label htmlFor={`image-${index}`}>
              <img src={`${API_BASE_URL}/${image}`} alt={` ${index + 1}`} className="other-image" />
            </label>
          </div>
        ))}
      </div>

      {/* Display Partner Description */}
      {description && (
        <div className="partner-description">
          <h3>{t('aboutMe')}</h3>
          <p>{description}</p>
        </div>
      )}

    <button className="subscribe-button" >{t('unsubscribe')}</button>

    <h2>{t('productsOnSale')}</h2>

      {/* Confirm and Submit Button */}
      <button className="confirm-button" onClick={handleSubmit}>Confirm and Submit</button>
      </div>
    </div>
    
  );
};

export default PartnerApplicationProcess;
