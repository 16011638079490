import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_BASE_URL } from '../../../config/start.mjs';
import './PartnershipApplicationManager.css';
import { useTranslation } from 'react-i18next';

const PartnerApplicationManager = () => {
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchApplications = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/admin/partnerapplications`, {
          headers: {
            'Content-Type': 'application/json',
          }
        });
        if (response.status === 200) {
          setApplications(response.data);
        }
      } catch (error) {
        console.error('Error fetching partnership applications:', error);
        setError('Failed to fetch partnership applications.');
      } finally {
        setLoading(false);
      }
    };

    fetchApplications();
  }, []);

  const handleProceed = (applicationId) => {
    navigate(`/admin/partner/application/${applicationId}/process`);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p className="error-message">{error}</p>;

  return (
    <div className="partnership-application-manager">
      <div className='admin-back-button-container'>
        <button className="back-button" onClick={() => navigate(-1)}>{t('back')}</button>
      </div>
      <h1>{t('managePartnershipApplications')}</h1>
      {applications.length === 0 ? (
        <p>{t('noPartnershipApplications')}</p>
      ) : (
        <ul>
          {applications.map((application) => (
            <li key={application._id} className="partnership-application-item">
              <p><strong>{t('userId')}:</strong> {application.userId}</p>
              <p><strong>{t('phoneNumber')}:</strong> {application.phoneNumber}</p>
              <p><strong>{t('email')}:</strong> {application.email}</p>
              <p><strong>{t('post')}:</strong> {application.post}</p>
              <p><strong>{t('status')}:</strong> {application.status}</p>
              <p><strong>{t('submissionDate')}:</strong> {new Date(application.submissionDate).toLocaleDateString()}</p>
              <p><strong>{t('reviewDate')}:</strong> {application.reviewDate ? new Date(application.reviewDate).toLocaleDateString() : t('notReviewed')}</p>
              
              <p><strong>{t('images')}:</strong></p>
              <div className="partnership-application-images">
                {application.images.map((image, index) => (
                  <img key={index} src={`${API_BASE_URL}/${image}`} alt={`Application ${index}`} className="application-image" />
                ))}
              </div>

              <p><strong>{t('comments')}:</strong></p>
              <ul>
                {application.comments.map((comment, index) => (
                  <li key={index}>
                    <p><strong>{t('commentBy')}:</strong> {comment.user}</p>
                    <p><strong>{t('comment')}:</strong> {comment.comment}</p>
                    <p><strong>{t('commentDate')}:</strong> {new Date(comment.date).toLocaleDateString()}</p>
                  </li>
                ))}
              </ul>
              <button onClick={() => handleProceed(application._id)}>{t('proceed')}</button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default PartnerApplicationManager;
