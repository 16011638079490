import React, { useContext, useState, useEffect, useRef, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../assets/5.png';
import './Header.css'; 
import axios from 'axios';
import { API_BASE_URL } from '../../config/start.mjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { AuthContext } from '../../context/AuthContext'; 
import {LanguageContext} from "../../context/LanguageContext";
import { useWebSocket } from '../../context/WebSocketContext';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';


const Header = () => {
  const { isAuthenticated, user, setUser, setIsAuthenticated } = useContext(AuthContext);
  const { updateLanguage, language } = useContext(LanguageContext);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [showLanguageDropdown, setShowLanguageDropdown] = useState(false);
  const navigate = useNavigate();

  const {socket, ready, registerMessageHandler, unregisterMessageHandler} = useWebSocket();
  const { t } = useTranslation();

  const dropdownRef = useRef(null);
  const notificationsRef = useRef(null);
  const bellIconRef = useRef(null);
  const languageDropdownRef = useRef(null);
  const unreadNotifications = useRef(user?.notifications?.filter(notification => !notification.read).length || 0);
  const [unreadCount, setUnreadCount] = useState(unreadNotifications.current);

  const handleClickOutside = useCallback((event) => {
    const isClickOutsideDropdown = dropdownRef.current && !dropdownRef.current.contains(event.target);
    const isClickOutsideNotifications = notificationsRef.current && !notificationsRef.current.contains(event.target);
    const isClickOutsideBellIcon = bellIconRef.current && !bellIconRef.current.contains(event.target);
    const isClickOutsideLanguageDropdown = languageDropdownRef.current && !languageDropdownRef.current.contains(event.target);
  
    // Close all UI elements if click is outside all of them
    if (isClickOutsideDropdown && isClickOutsideNotifications && isClickOutsideBellIcon && isClickOutsideLanguageDropdown) {
      setShowDropdown(false);
      setShowNotifications(false);
      setShowLanguageDropdown(false);
    } else {
      // If the click is outside the dropdown but inside the notifications, just close the dropdown
      if (isClickOutsideDropdown && !isClickOutsideNotifications) {
        setShowDropdown(false);
      }
      // If the click is outside the notifications but inside the dropdown, just close the notifications
      if (isClickOutsideNotifications && !isClickOutsideDropdown) {
        setShowNotifications(false);
      }
      // If the click is outside the language dropdown, close it
      if (isClickOutsideLanguageDropdown) {
        setShowLanguageDropdown(false);
      }
    }
  }, []);

  const markNotificationsAsRead = () => {
    const updateNotifications = user.notifications.map(notification => 
      notification.read ? notification : {...notification, read:true});
      socket.send(JSON.stringify({
        type: 'notificationsRead',
        data : {
          userId: user._id,
        }
      }));
      setUser(prevUser => ({...prevUser , notifications: updateNotifications}));
      unreadNotifications.current = 0;
      setUnreadCount(0);
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [handleClickOutside]);

  //WSS
  useEffect(() => {  
    if(ready && socket && isAuthenticated) {
      const handleNotificationUpdate = (data) => {
        console.log("Received Notification:", data);
        user.notifications.push(data)
        setUser(user);
        unreadNotifications.current = unreadNotifications.current + 1;
        setUnreadCount(unreadNotifications.current);
      };

      registerMessageHandler('notificationUpdate', handleNotificationUpdate);

    return () => {
    };
   }
  }, [ unreadNotifications, isAuthenticated, user, setUser, socket, registerMessageHandler, unregisterMessageHandler, ready]);

  useEffect(() => {
    const updateDropdownPosition = () => {
      if (bellIconRef.current && notificationsRef.current) {
        const bellIconRect = bellIconRef.current.getBoundingClientRect();
        const dropdownRect = notificationsRef.current.getBoundingClientRect();
  
        // Align the far right edge of the dropdown with the far right edge of the bell icon
        notificationsRef.current.style.left = `${bellIconRect.right - dropdownRect.width}px`;
        // Position the dropdown slightly below the bell icon
        notificationsRef.current.style.top = `${bellIconRect.bottom + 20}px`;
      }
    };
  
    updateDropdownPosition();
    window.addEventListener('resize', updateDropdownPosition);
  
    return () => {
      window.removeEventListener('resize', updateDropdownPosition);
    };
  }, [showNotifications]);
  //handles
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const toggleNotifications = () => {
    setShowNotifications(!showNotifications);
    // I put !showNotification because the change right above is not yet done at this point
    //the set function needs a rerender to make showNotification be true, so the logic is not 
    //natural here since we markNotificationaAsRead when showNotifications is still false.
    if (!showNotifications && unreadCount>0) {
      markNotificationsAsRead();
    }
  };

  const toggleLanguageDropdown = () => setShowLanguageDropdown(!showLanguageDropdown);

  const handleWallet = () => {
    navigate("/wallet");
  };

  const handleSettings = () => {
    navigate("/settings")
  };

  const handleHistory = () => {
    navigate("/history")
  };

  const handleAdmin = () => {
    navigate("/admindashboard");
  }

  const handleLogout = () => {
    Cookies.remove("accessToken");
    Cookies.remove("refreshToken");

    setUser(null);
    setIsAuthenticated(false);

    navigate("/login");
  };

  const handleDeleteNotification = async (originalIndex) => {
    try {
      // Make API call to delete the notification by the original index
      await axios.delete(`${API_BASE_URL}/api/notifications/${user._id}/${originalIndex}`);
  
      // Filter out the notification with the specific original index
      const updatedNotifications = user.notifications.filter((_, i) => i !== originalIndex);
  
      setUser(prevUser => ({
        ...prevUser,
        notifications: updatedNotifications
      }));
    } catch (error) {
      console.error('Error deleting notification:', error);
    }
  };

  const handleDeleteAll = async () => {
    try {
      await axios.delete(`${API_BASE_URL}/api/notifications/${user._id}`);
      setUser(prevUser => ({
        ...prevUser,
        notifications: []
      }));
    } catch (error) {
      console.error('Error deleting all notifications:', error);
    }
  };

  const handleNotificationClick = (link) => {
    if (link) {
      navigate(link);
    }
  };

  const handleLanguageChange = async (lang) => {
  
    if (isAuthenticated) {
      try {
        const response = await axios.put(`${API_BASE_URL}/api/user/settings/language`, { language: lang });
        if (response.status === 200) {
          updateLanguage(lang); 
          setShowLanguageDropdown(false); 
        }
      } catch (error) {
        console.error('Error updating language:', error);
      }
    } else {
      updateLanguage(lang); 
      setShowLanguageDropdown(false); 
    }
  };

  return (
    <header className='header-page'>
    <img src={logo} className='logo' alt="Logo" onClick={() => navigate('/')} />
    <nav>
      {isAuthenticated && (
        <>
          {user.role !== 'partner' && (
            <Link className='become-partner-link' to="/becomepartner">{t('becomePartner')}</Link>
          )}
          {user.role === 'partner' && (
            <Link className='partner-dashboard-link' to="/partner/dashboard">{t('dashboard')}</Link>
          )}
        </>
      )}
      <Link className='home-link' to="/">{t('home')}</Link>
      <Link className='partners-link' to="/partners">{t('partners')}</Link>
      {isAuthenticated ? (
        <>
          <div className="notification-icon" onClick={toggleNotifications} ref={bellIconRef}>
            <FontAwesomeIcon icon={faBell} />
            {unreadCount > 0 && <span className="notification-badge">{unreadCount}</span>}
          </div>
          {showNotifications && (
          <div className="notification-dropdown" ref={notificationsRef}>
            <div className="notification-header">
              <h4>{t('notifications')}</h4>
              <button className="delete-all" onClick={handleDeleteAll}>
                {t('deleteAll')}
              </button>
            </div>
            {user.notifications.length > 0 ? (
              user.notifications.slice().reverse().map((notification, index) => (
                <div
                  key={index} 
                  className={`notification-item ${notification.read ? 'read' : 'unread'}`}
                  onClick={() => handleNotificationClick(notification.link)}
                  style={{ cursor: notification.link ? 'pointer' : 'default' }} 
                >
                  <span>{notification.message}</span>
                  <button
                    className="delete-notification"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteNotification(user.notifications.length - 1 - index); 
                    }}
                  >
                    &times;
                  </button>
                </div>
              ))
              ) : (
                <div className="notification-item">{t('noNotifications')}</div>
              )}
            </div>
          )}
          <div className="dropdown" ref={dropdownRef}>
            <button onClick={toggleDropdown}>{t('myAccount')}</button>
            {showDropdown && (
              <div className="dropdown-content">
                <button onClick={handleSettings}>{t('settings')}</button>
                <button onClick={handleWallet}>{t('wallet')}</button>
                <button onClick={handleHistory}>{t('history')}</button>
                {user.role === 'admin' && (
                  <button onClick={handleAdmin}>{t('admin')}</button>
                )}
                <button onClick={handleLogout}>{t('logout')}</button>
              </div>
            )}
            <div className="user-wallet">
              {user?.wallet.amount !== undefined ? `${user.wallet.amount} €` : t('loadingWallet')}
            </div>
          </div>
        </>
      ) : (
        <Link className="login-link" to="/login">{t('login')}</Link>
      )}
      <div className="language-selector" ref={languageDropdownRef}>
        <button onClick={toggleLanguageDropdown}>
          {language === 'fr' ? 'FR' : 'EN'} <FontAwesomeIcon icon={faCaretDown} />
        </button>
        {showLanguageDropdown && (
          <div className="language-dropdown">
            <button onClick={() => handleLanguageChange('en')}>EN</button>
            <button onClick={() => handleLanguageChange('fr')}>FR</button>
          </div>
        )}
      </div>
    </nav>
  </header>
  );
  
};

export default Header;
