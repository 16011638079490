// src/components/LoginForm.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import "./LoginForm.css";
import { useTranslation } from 'react-i18next';

const LoginForm = ({ onLogin, isPosting }) => {
  const [identifier, setIdentifier] = useState(''); // Changed to identifier to handle both username and email
  const [password, setPassword] = useState('');
  const { t } = useTranslation();

  const handleSubmit = (e) => {
    e.preventDefault();
    // Trim identifier before calling onLogin
    onLogin(identifier.trim(), password);
  };

  return (
    <form onSubmit={handleSubmit} className='login-form'>
      <div>
        <label>{t('usernameOrEmail')}:</label> {/* Updated label */}
        <input
          type="text"
          value={identifier}
          onChange={(e) => setIdentifier(e.target.value)}
          required
        />
      </div>
      <div>
        <label>{t('password')}:</label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
      </div>
      <div className="forgot-password">
        <Link to="/resetpassword">{t('forgotPassword')}</Link>
      </div>
      <button className="login-button" type="submit" disabled={isPosting}>
        {isPosting ? t('loggingIn') : t('login')}
      </button>
    </form>
  );
};

export default LoginForm;
