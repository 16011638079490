import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { API_BASE_URL } from '../../../config/start.mjs';
import './DeliveryManager.css';
import { useTranslation } from 'react-i18next';

const DeliveryManager = () => {
  const [deliveries, setDeliveries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchDeliveries = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/admin/deliveries`, {
          headers: {
            'Content-Type': 'application/json',
          }
        });
        if (response.status === 200) {
          setDeliveries(response.data);
        }
      } catch (error) {
        console.error('Error fetching deliveries:', error);
        setError('Failed to fetch deliveries.');
      } finally {
        setLoading(false);
      }
    };

    fetchDeliveries();
  }, []);

  const handleMarkAsShipped = async (deliveryId) => {
    try {
      await axios.post(`${API_BASE_URL}/api/admin/deliveries/shipped`, { deliveryId }, {
        headers: {
          'Content-Type': 'application/json',
        }
      });
      setDeliveries(prevDeliveries => 
        prevDeliveries.map(delivery => 
          delivery._id === deliveryId ? { ...delivery, status: 'Shipped' } : delivery
        )
      );
    } catch (error) {
      console.error('Error marking delivery as shipped:', error);
      setError('Failed to mark delivery as shipped.');
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p className="error-message">{error}</p>;

  return (
    <div className="delivery-manager">
      <div className='admin-back-button-container'>
        <button className="back-button" onClick={() => navigate(-1)}>{t('back')}</button>
      </div>
      <h1>{t('manageDeliveries')}</h1>
      {deliveries.length === 0 ? (
        <p>{t('noDeliveries')}</p>
      ) : (
        <ul>
          {deliveries.map((delivery) => (
            <li key={delivery._id} className="delivery-item">
              <p><strong>{t('productName')}:</strong> {delivery.product.name}</p>
              <p><strong>{t('winner')}:</strong> {delivery.winner.username}</p>
              <p><strong>{t('deliveryDate')}:</strong> {new Date(delivery.date).toLocaleDateString()}</p>
              <p><strong>{t('status')}:</strong> {delivery.status}</p>
              <p><strong>{t('address')}:</strong> {delivery.shippingAddress.address1}, {delivery.shippingAddress.city}</p>
              <div className="button-container">
                <Link to={`/admin/deliveries/view/${delivery._id}`}>
                  <button className="view-details-button">
                    {t('viewDetails')}
                  </button>
                </Link>
                <button 
                  className="mark-shipped-button" 
                  disabled={delivery.status === 'Shipped'} 
                  onClick={() => handleMarkAsShipped(delivery._id)}
                >
                  {t('markAsShipped')}
                </button>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
  
  
};

export default DeliveryManager;
