import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import LoginForm from '../../components/LoginForm/LoginForm';
import Cookies from 'js-cookie';
import "./Login.css";
import { API_BASE_URL } from '../../config/start.mjs';
import { AuthContext } from '../../context/AuthContext';
import { useTranslation } from 'react-i18next';

const Login = () => {
  const { setUser, setIsAuthenticated, isAuthenticated } = useContext(AuthContext);
  const [isPosting, setIsPosting] = useState(false);
  const navigate = useNavigate();
  axios.defaults.withCredentials = true;
  const { t } = useTranslation();

  useEffect(() => {
    if (isAuthenticated === true) navigate('/');
  }, [isAuthenticated, navigate]);

  const handleLogin = async (username, password) => {
    setIsPosting(true);
    try {
      const response = await axios.post(`${API_BASE_URL}/api/auth`, { username, password });
      const { user, accessToken, refreshToken } = response.data;

      Cookies.set('accessToken', accessToken, { expires: 1 / 24 }); // Expires in 1 hour
      Cookies.set('refreshToken', refreshToken, { expires: 200 }); // Expires in 200 days

      setUser(user);
      setIsAuthenticated(true);
      console.log('Login success:', response.data);
      setIsPosting(false);
      navigate('/');
    } catch (error) {
      setIsPosting(false);
      if (error.response) {
        console.error('Error:', error.response.data);
        alert('Login Failed: ' + error.response.data.message);
      } else {
        console.error('Error:', error.message);
        alert('Login Failed: An unexpected error occurred.');
      }
    }
  };

  return (
    <div className='login-container'>
      <h1>{t('login')}</h1>
      {!isAuthenticated ? (
        <div className="login-form-container">
          <LoginForm onLogin={handleLogin} isPosting={isPosting} />
          <p className="register-link">
            {t('notAUserYet')} <Link to="/register">{t('clickToCreateAccount')}</Link>
          </p>
        </div>
      ) : (
        <p>{t('alreadyLoggedIn')}</p>
      )}
    </div>
  );
};

export default Login;
