import React from 'react';
import './Modal.css'; 
import { useTranslation } from 'react-i18next';

const Modal = ({ isOpen, onClose, message, success }) => {
    const { t } = useTranslation();

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        {success && (<h2>{t('confirmation')}</h2>)}
        {!success && (<h2>{t('error')}</h2>)}
        <p>{t(`${message}`)}</p>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default Modal;
