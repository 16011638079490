import React, { useState } from 'react';
import axios from 'axios';
import { API_BASE_URL } from '../../config/start.mjs';
import './RegisterForm.css';
import { useTranslation } from 'react-i18next';

const RegisterForm = ({ setShowSuccessModal, setShowErrorModal }) => {
  const [formData, setFormData] = useState({
    birthDate: '', 
    email: '',
    username: '',
    password: '',
    acceptCGU: false,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [usernameError, setUsernameError] = useState('');
  const { t } = useTranslation();

  const isOver18 = (birthDate) => {
    const today = new Date();
    const birthDateObj = new Date(birthDate);
    const age = today.getFullYear() - birthDateObj.getFullYear();
    const monthDiff = today.getMonth() - birthDateObj.getMonth();

    // Adjust age if birthday hasn't occurred yet this year
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDateObj.getDate())) {
      return age - 1 >= 18;
    }

    return age >= 18;
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (name === 'birthDate') {
      // Validate date field to ensure it's a valid date
      const validDate = Date.parse(value);
      if (isNaN(validDate)) return; // Ignore invalid dates
    }

    if (name === 'username') {
      // Real-time validation for username
      if (value.includes('@')) {
        setUsernameError(t('username_contains_at'));
      } else {
        setUsernameError('');
      }
    }

    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.username.includes('@')) {
      setUsernameError(t('username_contains_at'));
      return;
    }

    if (!isOver18(formData.birthDate)) {
      alert(t('age_restriction'));
      return;
    }

    if (!formData.acceptCGU) {
      alert(t('accept_cgu'));
      return;
    }

    setIsSubmitting(true);
    try {
      const response = await axios.post(`${API_BASE_URL}/api/newUser`, formData);
      console.log('Registration success:', response.data);
      setShowSuccessModal(true);
      setIsSubmitting(false);
    } catch (error) {
      setIsSubmitting(false);
      if (error.response) {
        console.error('Error:', error.response.data);
        if(error.response.data.message !== 'usernameTaken'){
        setShowErrorModal(true);
        }
        alert(t('registration_failed') + t(`${error.response.data.message}`));
      } else {
        console.error('Error:', error.message);
        alert(t('unexpected_error'));
      }
    }
  };

  return (
    <form onSubmit={handleSubmit} className="register-form">
      <div>
        <label>{t('birthdate')}:</label>
        <input
          type="date"
          name="birthDate"
          value={formData.birthDate}
          onChange={(e) => {
            // Convert the date to the desired format (YYYY-MM-DD)
            const date = new Date(e.target.value);
            const formattedDate = date.toISOString().split('T')[0]; // Ensures ISO format
            handleChange({ target: { name: 'birthDate', value: formattedDate } });
          }}
          required
          max={new Date().toISOString().split('T')[0]} // Prevent future dates
        />
      </div>
      <div>
        <label>{t('email')}:</label>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
          maxLength="100" // Limit length for email
        />
      </div>
      <div>
        <label>{t('username')}:</label>
        <input
          type="text"
          name="username"
          value={formData.username}
          onChange={handleChange}
          required
          maxLength="30" // Limit length for username
        />
        {usernameError && <p className="error-message">{usernameError}</p>}
      </div>
      <div>
        <label>{t('password')}:</label>
        <input
          type="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          required
          minLength="8"
          autoComplete="new-password" // Ensure a minimum length for passwords
        />
      </div>
      <div>
        <label>
          <input
            type="checkbox"
            name="acceptCGU"
            checked={formData.acceptCGU}
            onChange={handleChange}
            required
          />
          {t('accept_cgu')}
        </label>
        <a href="/cgu" target="_blank" rel="noopener noreferrer">
          {t('view_cgu')}
        </a>
      </div>
      <button type="submit" disabled={isSubmitting}>
        {isSubmitting ? t('registering') : t('register')}
      </button>
    </form>
  );
};

export default RegisterForm;
